import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
  // 数据
  state: {
    userMenu: [],
    menuList: [],
    curSubmenus: [],
    hasChange: false,
    selectedSubmenuId: "",
    selectedTopMenuId: "",
    isModify: false,
    addressInfo: null
  },
  mutations: {
    SET_USER_MENU(state, menus) {
      state.userMenu = menus;
    },
    SET_MENU_LIST(state, menusList) {
      state.menuList = menusList;
    },
    SET_CUR_SUB_MENU(state, subMenus) {
      state.curSubmenus = subMenus;
    },
    SET_CUR_SUB_MENU_KEY(state, menuKey) {
      state.selectedSubmenuId = menuKey;
    },
    SET_CUR_TOP_MENU_KEY(state, menuKey) {
      state.selectedTopMenuId = menuKey;
    },
    SET_IS_MODIFY(state, isModify) {
      state.isModify = isModify;
    }
  },
  actions: {
    setUserMenu({ commit }, menus) {
      commit("SET_USER_MENU", menus);
    },
    setMenuList({ commit }, menusList) {
      commit("SET_MENU_LIST", menusList);
    },
    setCurSubMenuList({ commit }, subMenus) {
      commit("SET_CUR_SUB_MENU", subMenus);
    },
    setCurSubMenu({ commit }, subMenuId) {
      commit("SET_CUR_SUB_MENU_KEY", subMenuId);
    },
    setIsModify({ commit }, isModify) {
      commit("SET_IS_MODIFY", isModify);
    },
    setCurTopMenuKey({ commit }, menuId) {
      commit("SET_CUR_TOP_MENU_KEY", menuId);
    },
    logoutUser({ commit }) {
      commit("SET_USER_MENU", []);
      commit("SET_MENU_LIST", []);
      commit("SET_CUR_SUB_MENU", []);
      commit("SET_CUR_TOP_MENU_KEY", "");
      commit("SET_CUR_SUB_MENU_KEY", "");
    }
  },
  plugins: [createPersistedState()]
});
