<template>
  <a-time-picker
    format="HH:mm"
    :default-value="dateValue"
    :open="open"
    @openChange="handleOpenChange"
    :value="dateValue"
    @change="onChange"
  />
</template>

<script>
import Moment from "moment";

export default {
  props: {
    field: {
      type: Object,
      default: () => ({})
    },
    form: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      open: false
    };
  },
  computed: {
    dateValue() {
      return this.form[this.field.key] ? Moment(this.form[this.field.key]) : "";
    }
  },
  methods: {
    handleOpenChange(open) {
      this.open = open;
    },
    onChange(time) {
      this.open = false;
      this.$emit("change", time);
    }
  }
};
</script>
