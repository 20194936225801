/* eslint-disable */
import Vue from "vue";
import VueRouter from "vue-router";
import NProgress from "nprogress";
import { Modal } from "ant-design-vue";
import store from "../store/store";

Vue.prototype.confirm = Modal.confirm;

//解决点击相同菜单报NavigationDuplicated错误
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
};

Vue.use(VueRouter);

const routes = [
  {
    path: "/adm",
    name: "Admin",
    // meta: {
    //   requiresAuth: true
    // },
    component: () => import(/* webpackChunkName: "sys" */ "../views/Admin.vue"),
    children: [
      {
        path: "/home",
        name: "Home",
        component: () =>
          import(/* webpackChunkName: "home" */ "../views/Home.vue")
      },
      // 原厂库管理
      {
        path: "/factory-list",
        name: "FactoryManage",
        component: () =>
          import(
            /* webpackChunkName: "home" */ "../views/factory/FactoryManage.vue"
            )
      },
      // 原厂库管理详情
      {
        path: "/factory-detail/:type/:factoryId",
        name: "FactoryDetail",
        component: () =>
          import(
            /* webpackChunkName: "home" */ "../views/factory/FactoryDetail.vue"
            )
      },
      // 待审核列表
      {
        path: "/examine-list",
        name: "ExamineList",
        meta: {
          keepAlive: true,
          checkPermission: true
        },
        component: () =>
          import(
            /* webpackChunkName: "examine" */ "../views/examine/ExamineList.vue"
            )
      },
      // 待审核列表详情
      {
        path: "/examine-detail/:applicationId/:type",
        name: "ExamineDetail",
        meta: {
          fatherName: ["ExamineList", "DoneTask"]
        },
        component: () =>
          import(
            /* webpackChunkName: "examine" */ "../views/examine/ExamineDetail.vue"
            )
      },
      // 待审核列表详情 - 发起协议
      {
        path: "/examine-detail/:applicationId/:type",
        name: "ModifyAgreement",
        meta: {
          fatherName: ["ExamineList"]
        },
        component: () =>
          import(
            /* webpackChunkName: "ModifyAgreement" */ "../views/examine/ExamineDetail.vue"
            )
      },
      // 已审核列表
      {
        path: "/examined-list",
        name: "ExaminedList",
        component: () =>
          import(
            /* webpackChunkName: "examine" */ "../views/examine/ExaminedList.vue"
            )
      },
      // 已审核列表详情
      {
        path: "/examined-detail/:dealerId",
        name: "ExaminedDetail",
        component: () =>
          import(
            /* webpackChunkName: "examine" */ "../views/examine/ExaminedDetail.vue"
          )
      },
      // 试骑活动列表
      {
        path: "/ride-activity",
        name: "RideActivityList",
        meta: {
          keepAlive: true,
          checkPermission: true
        },
        component: () =>
          import(
            /* webpackChunkName: "examine" */ "../views/examinedActivity/RideActivityList"
            )
      },
      // 试骑活动列表
      {
        path: "/check-big-map/:id",
        name: "CheckBigMap",
        meta: {
          fatherName: ["ExaminedActivityList"]
        },
        component: () =>
          import(
            /* webpackChunkName: "examine" */ "../views/examinedActivity/CheckBigMap"
            )
      },
      // 审核试骑活动
      {
        path: "/examined-activity/:id/:action",
        name: "ExaminedActivityList",
        meta: {
          keepAlive: true,
          fatherName: ["RideActivityList", "ToDoTask", "DoneTask"]
        },
        component: () =>
          import(
            /* webpackChunkName: "examine" */ "../views/examinedActivity/ExaminedActivity"
            )
      },
      // 租赁活动列表
      {
        path: "/lease-activity",
        name: "LeaseActivityList",
        meta: {
          keepAlive: true,
          checkPermission: true
        },
        component: () =>
          import(
            /* webpackChunkName: "examine" */ "../views/examinedActivity/LeaseActivityList"
            )
      },
      // 审核价值包列表
      {
        path: "/value-examined",
        name: "ValueExaminedList",
        component: () =>
          import(
            /* webpackChunkName: "examine" */ "../views/valueBagManagement/ValueExaminedList"
            ),
        meta: {
          checkPermission: true
        }
      },
      // 审核价值包
      {
        path: "/value-examined/:id/:action",
        name: "ValueExaminedDetail",
        meta: {
          fatherName: ["ToDoTask", "DoneTask"]
        },
        component: () =>
          import(
            /* webpackChunkName: "examine" */ "../views/valueBagManagement/ValueExaminedDetail"
            )
      },
      // 审核租赁活动
      {
        path: "/examined-lease-activity/:id/:action",
        name: "ExaminedLeaseActivity",
        meta: {
          fatherName: ["LeaseActivityList","ToDoTask", "DoneTask"]
        },
        component: () =>
          import(
            /* webpackChunkName: "examine" */ "../views/examinedActivity/ExaminedLeaseActivity"
            )
      },
      // 产品管理
      {
        path: "/product-manage",
        name: "ProductManage",
        component: () =>
          import(
            /* webpackChunkName: "product" */ "../views/product/ProductManage"
          )
      },
      // 商品序列号管理
      {
        path: "/sku-serial",
        name: "SkuSerialManage",
        component: () =>
          import(
            /* webpackChunkName: "examine" */ "../views/product/SkuSerialManage"
            ),
        meta: {
          checkPermission: true
        }
      },
      // 价格管理
      {
        path: "/product-value-manage",
        name: "ProductValueManage",
        component: () =>
          import(
            /* webpackChunkName: "ProductValueManage" */ "../views/product/ProductValueManage"
            ),
        meta: {
          checkPermission: true
        }
      },
      // 价格查看
      {
        path: "/product-value-detail/:id",
        name: "ProductValueDetail",
        component: () =>
          import(
            /* webpackChunkName: "ProductValueDetail" */ "../views/product/ProductValueDetail"
            )
      },
      // 试骑订单
      {
        path: "/trial-ride-order",
        name: "TrialRideOrder",
        component: () =>
          import(
            /* webpackChunkName: "TrialRideOrder" */ "../views/cTerminalData/TrialRideOrder"
            ),
        meta: {
          checkPermission: true
        }
      },
      // 审核订单
      {
        path: "/trial-lease-order-pxz",
        name: "TrialLeaseOrderPxz",
        component: () =>
          import(
            /* webpackChunkName: "TrialRideOrder" */ "../views/cTerminalData/TrialLeaseOrderPxz"
            ),
        meta: {
          checkPermission: true
        }
      },
      // 试骑订单详情
      {
        path: "/trial-ride-detail/:id",
        name: "TrialRideDetail" ,
        component: () =>
          import(
            /* webpackChunkName: "TrialRideOrder" */ "../views/cTerminalData/TrialRideDetail"
            )
      },
      // 租赁订单详情
      {
        path: "/trial-lease-detail-pxz/:id",
        name: "TrialLeaseDetailPxz",
        component: () =>
          import(
            /* webpackChunkName: "TrialRideOrder" */ "../views/cTerminalData/TrialLeaseDetailPxz"
            )
      },
      //零售订单
      {
        path: "/retail-order",
        name: "retailOrder",
        meta: {
          keepAlive: true,
          checkPermission: true
        },
        component: () =>
          import(
            /* webpackChunkName: "TrialRideOrder" */ "../views/cTerminalData/retailOrder"
            )
      },
      // 零售订单 --> 订单详情
      {
        path: "/retail-order-detail",
        name: "retailOrderDetail",
        meta: {
          fatherName: ['retailOrder']
        },
        component: () =>
          import(
            /* webpackChunkName: "TrialRideOrder" */ "../views/cTerminalData/retailOrderDetail.vue"
            )
      },
      //用户数据
      {
        path: "/order-data",
        name: "clientOrderData",
        meta: {
          keepAlive: true,
          checkPermission: true
        },
        component: () =>
          import(
            /* webpackChunkName: "TrialRideOrder" */ "../views/cTerminalData/clientOrderData.vue"
            )
      },
      // 用户详情
      {
        path: "/consumer-detail",
        name: "webConsumerDetail",
        meta:{
          fatherName: ['clientOrderData']
        },
        component: () =>
          import(
            /* webpackChunkName: "TrialRideOrder" */ "../views/cTerminalData/webConsumerDetail.vue"
            )
      },
      // 新增产品
      {
        path: "/product-add/:type",
        name: "ProductAdd",
        component: () =>
          import(
            /* webpackChunkName: "product" */ "../views/product/ProductAdd"
          )
      },
      // 商品管理
      {
        path: "/commodity-manage",
        name: "CommodityManage",
        component: () =>
          import(
            /* webpackChunkName: "CommodityManage" */ "../views/commodity/CommodityManage"
          )
      },
      // 商品详情
      {
        path: "/commodity-detail/:productId",
        name: "CommodityDetail",
        component: () =>
          import(
            /* webpackChunkName: "CommodityDetail" */ "../views/commodity/CommodityDetail"
          )
      },
      // 经销商管理
      {
        path: "/dealer-manage",
        name: "DealerManage",
        component: () =>
          import(
            /* webpackChunkName: "DealerManage" */ "../views/dealer/DealerManage"
          )
      },
      // 新增经销商
      {
        path: "/dealer-add",
        name: "DealerAdd",
        meta: {
          isShowLeave: true
        },
        component: () =>
          import(
            /* webpackChunkName: "DealerAdd" */ "../views/dealer/DealerAdd"
            )
      },
      // 经销商详情
      {
        path: "/dealer-detail/:factoryVenuId",
        name: "DealerDetail",
        component: () =>
          import(
            /* webpackChunkName: "DealerDetail" */ "../views/dealer/DealerDetail"
          )
      },
      // 整车产品列表
      {
        path: "/complete-vehicle",
        name: "CompleteVehicle",
        meta: {
          keepAlive: true,
          checkPermission: true
        },
        component: () =>
          import(
            /* webpackChunkName: "CompleteVehicle" */ "../views/product/CompleteVehicle"
            )
      },
      // 整车产品详情
      {
        path: "/complete-vehicle-detail/:productId",
        name: "CompleteVehicleDetail",
        meta: {
          isShowLeave: true,
          fatherName:['CompleteVehicle']
        },
        component: () =>
          import(
            /* webpackChunkName: "CompleteVehicle" */ "../views/product/CompleteVehicleDetail"
            )
      },
      // 整车产品新增
      {
        path: "/complete-vehicle-info",
        name: "CompleteVehicleAdd",
        meta: {
          isShowLeave: true,
          fatherName:['CompleteVehicle']
        },
        component: () =>
          import(
            /* webpackChunkName: "CompleteVehicle" */ "../views/product/CompleteVehicleAdd"
            )
      },
      // 配件产品列表
      {
        path: "/parts",
        name: "Parts",
        component: () =>
          import(/* webpackChunkName: "Parts" */ "../views/product/Parts"),
        meta: {
          checkPermission: true
        }
      },
      // 配件产品详情
      {
        path: "/parts-detail/:type/:id",
        name: "PartsDetail",
        meta: {
          isShowLeave: true
        },
        component: () =>
          import(/* webpackChunkName: "Parts" */ "../views/product/PartsDetail")
      },
      // 配件产品新增
      {
        path: "/parts-detail/:type",
        name: "PartsAdd",
        meta: {
          isShowLeave: true
        },
        component: () =>
          import(/* webpackChunkName: "Parts" */ "../views/product/PartsAdd")
      },
      // 待审核订单
      {
        path: "/to-examine-order",
        name: "ToExamineOrder",
        component: () =>
          import(
            /* webpackChunkName: "ToExamineOrder" */ "../views/order/ToExamineOrder"
            )
      },
      // 待审核订单详情
      {
        path: "/to-examine-detail/:orderSn",
        name: "ToExamineDetail",
        component: () =>
          import(
            /* webpackChunkName: "ToExamineDetail" */ "../views/order/ToExamineDetail"
            )
      },
      // 未交付订单
      {
        path: "/undelivered-order",
        name: "UndeliveredOrder",
        component: () =>
          import(
            /* webpackChunkName: "UndeliveredOrder" */ "../views/order/UndeliveredOrder"
            )
      },
      // 未交付订单详情
      {
        path: "/undelivered-detail/:procureOrderSn",
        name: "UndeliveredDetail",
        component: () =>
          import(
            /* webpackChunkName: "UndeliveredDetail" */ "../views/order/UndeliveredDetail"
            )
      },
      // 已交付订单详情
      {
        path: "/delivered-detail/:orderSn",
        name: "DeliveredDetail",
        component: () =>
          import(
            /* webpackChunkName: "UndeliveredDetail" */ "../views/order/DeliveredDetail"
            )
      },
      // 关联订单列表
      {
        path: "/associated-order/:orderSn",
        name: "AssociatedOrder",
        component: () =>
          import(
            /* webpackChunkName: "AssociatedOrder" */ "../views/order/AssociatedOrder"
            )
      },
      // 发货
      {
        path: "/deliver-goods/:orderSn",
        name: "DeliverGoods",
        meta: {
          isShowLeave: true
        },
        component: () =>
          import(
            /* webpackChunkName: "DeliverGoods" */ "../views/order/DeliverGoods"
            )
      },
      // 发货地址
      {
        path: "/shipping-address/:procureOrderSn",
        name: "ShippingAddress",
        meta: {
          fatherName: ["PublicDeliver"]
        },
        component: () =>
          import(
            /* webpackChunkName: "ShippingAddress" */ "../views/order/ShippingAddress"
            )
      },
      // 已交付订单
      {
        path: "/delivered-order",
        name: "DeliveredOrder",
        component: () =>
          import(
            /* webpackChunkName: "DeliveredOrder" */ "../views/order/DeliveredOrder"
            )
      },
      // 无效订单
      {
        path: "/invalid-order",
        name: "InvalidOrder",
        component: () =>
          import(
            /* webpackChunkName: "InvalidOrder" */ "../views/order/InvalidOrder"
            )
      },
      // 无效订单详情
      {
        path: "/invalid-detail/:orderSn",
        name: "InvalidDetail",
        component: () =>
          import(
            /* webpackChunkName: "InvalidOrder" */ "../views/order/InvalidDetail"
            )
      },

      // 拼单列表
      {
        path: "/spell-list",
        name: "SpellList",
        component: () =>
          import(/* webpackChunkName: "SpellList" */ "../views/order/SpellList")
      },
      // 拼单详情
      {
        path: "/spell-detail/:orderSn",
        name: "SpellDetail",
        component: () =>
          import(
            /* webpackChunkName: "SpellDetail" */ "../views/order/SpellDetail"
            )
      },
      // 编辑序列号
      {
        path:
          "/edit-serial/:orderSn/:childrenOrderSn/:totalQuantity/:quantityShipped",
        name: "EditSerialSpell",
        meta: {
          isShowLeave: true
        },
        component: () =>
          import(
            /* webpackChunkName: "EditSerial" */ "../views/order/EditSerial"
            )
      },
      // 发货明细
      {
        path: "/delivery-detail/:logisticsId/:orderSn",
        name: "DeliveryDetails",
        component: () =>
          import(
            /* webpackChunkName: "DeliveryDetails" */ "../views/order/DeliveryDetails"
            )
      },
      // 修改BOM
      {
        path: "/bom/:type/:skuId/:productId",
        name: "ModifyBOM",
        meta: {
          isShowLeave: true
        },
        component: () =>
          import(/* webpackChunkName: "BOM" */ "../views/product/BOM")
      },
      // 新增BOM
      {
        path: "/bom/:type",
        name: "AddBOM",
        meta: {
          isShowLeave: true
        },
        component: () =>
          import(/* webpackChunkName: "BOM" */ "../views/product/BOM")
      },
      // 渠道终端档案
      {
        path: "/channel-terminal-archives",
        name: "ChannelTerminalArchives",
        meta: {
          keepAlive: true,
          checkPermission: true
        },
        component: () =>
          import(
            /* webpackChunkName: "ChannelTerminalArchives" */ "../views/archives/ChannelTerminalArchives"
            )
      },
      // 新建档案
      {
        path: "/add-archives/:type/:action",
        name: "AddArchives",
        meta: {
          fatherName: ['ChannelTerminalArchives','ServiceCenterArchives','OperatingCenterArchives','FactoryArchives','WarehouseArchives']
        },
        component: () =>
          import(
            /* webpackChunkName: "AddArchives" */ "../views/archives/AddArchives"
            )
      },
      // 查看档案
      {
        path: "/check-archives/:type/:action/:id",
        name: "CheckArchives",
        meta: {
          fatherName: ['ChannelTerminalArchives','ServiceCenterArchives','OperatingCenterArchives','FactoryArchives','WarehouseArchives']
        },
        component: () =>
          import(
            /* webpackChunkName: "AddArchives" */ "../views/archives/checkArchives"
            )
      },
      // 修改档案
      {
        path: "/modify-archives/:type/:action/:id",
        name: "ModifyArchives",
        meta: {
          fatherName: ['ChannelTerminalArchives','ServiceCenterArchives','OperatingCenterArchives','FactoryArchives','WarehouseArchives']
        },
        component: () =>
          import(
            /* webpackChunkName: "AddArchives" */ "../views/archives/AddArchives"
            )
      },
      //服务中心
      {
        path: "/service-center-archives",
        name: "ServiceCenterArchives",
        meta: {
          keepAlive: true,
          checkPermission: true
        },
        component: () =>
          import(
            /* webpackChunkName: "ServiceCenterArchives" */ "../views/archives/ServiceCenterArchives"
            )
      },
      //运营中心
      {
        path: "/operating-center-archives",
        name: "OperatingCenterArchives",
        meta: {
          keepAlive: true,
          checkPermission: true
        },
        component: () =>
          import(
            /* webpackChunkName: "OperatingCenterArchives" */ "../views/archives/OperatingCenterArchives"
            )
      },
      //制造原厂
      {
        path: "/factory-archives",
        name: "FactoryArchives",
        meta: {
          keepAlive: true,
          checkPermission: true
        },
        component: () =>
          import(
            /* webpackChunkName: "FactoryArchives" */ "../views/archives/FactoryArchives"
            )
      },
      //货栈
      {
        path: "/warehouse-archives",
        name: "WarehouseArchives",
        meta: {
          keepAlive: true,
          checkPermission: true
        },
        component: () =>
          import(
            /* webpackChunkName: "WarehouseArchives" */ "../views/archives/WarehouseArchives"
            )
      },
      //组织管理
      {
        path: "/organizational-management",
        name: "OrganizationalManagement",
        component: () =>
          import(
            /* webpackChunkName: "OrganizationalManagement" */ "../views/archives/OrganizationalManagement"
            ),
        meta: {
          checkPermission: true
        }
      },
      //类型管理
      {
        path: "/type-management",
        name: "TypeManagement",
        component: () =>
          import(
            /* webpackChunkName: "TypeManagement" */ "../views/valueBagManagement/TypeManagement"
            ),
        meta: {
          checkPermission: true
        }
      },
      //配置策略
      {
        path: "/strategy-deploy",
        name: "StrategyDeploy",
        meta: {
          keepAlive: true,
          checkPermission: true
        },
        component: () =>
          import(
            /* webpackChunkName: "StrategyDeploy" */ "../views/valueBagManagement/StrategyConfig"
            )
      },
      //创建策略
      {
        path: "/add-strategy",
        name: "AddStrategy",
        component: () =>
          import(
            /* webpackChunkName: "StrategyDeploy" */ "../views/valueBagManagement/AddStrategy"
            )
      },
      //修改策略
      {
        path: "/modify-strategy/:id",
        name: "ModifyStrategy",
        component: () =>
          import(
            /* webpackChunkName: "StrategyDeploy" */ "../views/valueBagManagement/AddStrategy"
            )
      },
      //配置规则
      {
        path: "/strategy-rule/:id",
        name: "StrategyRule",
        component: () =>
          import(
            /* webpackChunkName: "StrategyRule" */ "../views/valueBagManagement/StrategyRule"
            )
      },
      //新增价值包规则
      {
        path: "/add-strategy-rule/:id",
        name: "AddStrategyRule",
        component: () =>
          import(
            /* webpackChunkName: "AddStrategyRule" */ "../views/valueBagManagement/AddStrategyRule"
            )
      },
      //修改价值包规则
      {
        path: "/modify-strategy-rule/:id/:policyId",
        name: "ModifyStrategyRule",
        component: () =>
          import(
            /* webpackChunkName: "AddStrategyRule" */ "../views/valueBagManagement/AddStrategyRule"
            )
      },
      // 三方协议管理
      {
        path: "/tripartite-agreement-manage",
        name: "TripartiteAgreementManage",
        meta: {
          keepAlive: true,
          checkPermission: true
        },
        component: () =>
          import(
            /* webpackChunkName: "TripartiteAgreementManage" */ "../views/agreement/TripartiteAgreementManage"
            )
      },
      // 发起协议
      {
        path: "/initiate-agreement",
        name: "InitiateAgreement",
        meta: {
          fatherName: ["TripartiteAgreementManage"]
        },
        component: () =>
          import(
            /* webpackChunkName: "InitiateAgreement" */ "../views/agreement/InitiateAgreement"
            )
      },
      // 修改协议
      {
        path: "/initiate-agreement/:type/:contractNo",
        name: "ModifyTripartiteAgreement",
        meta: {
          fatherName: ["TripartiteAgreementManage"]
        },
        component: () =>
          import(
            /* webpackChunkName: "ModifyTripartiteAgreement" */ "../views/agreement/InitiateAgreement"
            )
      },
      // 上传协议
      {
        path: "/initiate-agreement/:type/:contractNo",
        name: "UploadTripartiteAgreement",
        meta: {
          fatherName: ["ToDoTask"]
        },
        component: () =>
          import(
            /* webpackChunkName: "UploadTripartiteAgreement" */ "../views/agreement/InitiateAgreement"
            )
      },
      // 协议详情
      {
        path: "/tripartite-agreement-detail/:contractNo",
        name: "TripartiteAgreementDetail",
        meta: {
          fatherName: ["DoneTask"]
        },
        component: () =>
          import(
            /* webpackChunkName: "TripartiteAgreementDetail" */ "../views/agreement/TripartiteAgreementDetail"
            )
      },
      // 发货页面
      {
        path: "/public-deliver/:orderSn/:type",
        name: "PublicDeliver",
        meta: {
          keepAlive: true,
          fatherName: ["ToDeliverOrderList", "PurchaseOrderListFactory"]
        },
        component: () =>
          import(
            /* webpackChunkName: "PublicDeliver" */ "../views/order/PublicDeliver"
            )
      },
      // 采购订单
      {
        path: "/factory/purchase-order-list",
        name: "PurchaseOrderListFactory",
        meta: {
          keepAlive: true,
          checkPermission: true
        },
        component: () =>
          import(
            /* webpackChunkName: "PurchaseOrderList" */ "../views/orderManage/purchaseOrderFactory/PurchaseOrderList"
            )
      },
      // 采购订单详情 - 原厂
      {
        path: "/factory/purchase-order-detail/:orderSn/:type",
        name: "PurchaseOrderDetailFactory",
        meta: {
          fatherName: ["PurchaseOrderListFactory"]
        },
        component: () =>
          import(
            /* webpackChunkName: "PurchaseOrderDetail" */ "../views/orderManage/purchaseOrderFactory/PurchaseOrderDetail"
            )
      },
      // 采购订单审核
      {
        path: "/factory/purchase-order-examine/:orderSn/:type",
        name: "PurchaseOrderExamineFactory",
        meta: {
          fatherName: ["PurchaseOrderListFactory"]
        },
        component: () =>
          import(
            /* webpackChunkName: "PurchaseOrderDetail" */ "../views/orderManage/purchaseOrderFactory/PurchaseOrderDetail"
            )
      },
      // 采购待审核订单 - 原厂
      {
        path: "/factory/purchase-order-examine-list",
        name: "PurchaseOrderExamineListFactory",
        component: () =>
          import(
            /* webpackChunkName: "PurchaseOrderExamineList" */ "../views/orderManage/purchaseOrderFactory/PurchaseOrderExamineList"
            ),
        meta: {
          checkPermission: true
        }
      },
      // 采购待审核订单 - 运营总部
      {
        path: "/operation/purchase-order-examine-list",
        name: "PurchaseOrderExamineListOperation",
        component: () =>
          import(
            /* webpackChunkName: "PurchaseOrderExamineList" */ "../views/orderManage/purchaseOrderOperation/PurchaseOrderExamineList"
            ),
        meta: {
          checkPermission: true
        }
      },
      // 采购订单审核 - 运行总部
      {
        path: "/operation/purchase-order-examine/:orderSn/:type",
        name: "PurchaseOrderExamineOperation",
        meta: {
          keepAlive: true,
          fatherName: ["ToDoTask", "DoneTask"]
        },
        component: () =>
          import(
            /* webpackChunkName: "PurchaseOrderDetail" */ "../views/orderManage/purchaseOrderOperation/PurchaseOrderDetail"
            )
      },
      // 采购订单详情 - 运营总部
      {
        path: "/operation/purchase-order-detail/:orderSn/:type/:status",
        name: "PurchaseOrderDetailOperation",
        meta: {
          fatherName: ["OperationPurchaseOrderList"]
        },
        component: () =>
          import(
            /* webpackChunkName: "PurchaseOrderDetailOperation" */ "../views/orderManage/purchaseOrderOperation/PurchaseOrderDetail"
            )
      },
      // 付款审核
      {
        path: "/pay-examine",
        name: "PayExamine",
        meta: {
          keepAlive: true,
          checkPermission: true
        },
        component: () =>
          import(
            /* webpackChunkName: "PayExamine" */ "../views/financialManage/PayExamine"
            )
      },
      // 付款订单审核
      {
        path: "/pay-order-examine/:orderSn/:type",
        name: "PayOrderExamine",
        component: () =>
          import(
            /* webpackChunkName: "PayOrderExamine" */ "../views/financialManage/PayExamineDetail"
            )
      },
      // 付款订单详情
      {
        path: "/pay-order-detail/:orderSn/:type",
        name: "PayOrderDetail",
        meta: {
          fatherName: ["OperationPurchaseOrderList", "ToDoTask", "DoneTask"]
        },
        component: () =>
          import(
            /* webpackChunkName: "PayOrderDetail" */ "../views/financialManage/PayExamineDetail"
            )
      },
      // 关联订单
      {
        path: "/relevance-order/:orderSn",
        name: "RelevanceOrder",
        component: () =>
          import(
            /* webpackChunkName: "RelevanceOrder" */ "../views/financialManage/RelevanceOrder"
            )
      },
      // 账户余额 - 运营总部
      {
        path: "/account-balance-operation",
        name: "AccountBalanceOperation",
        component: () =>
          import(
            /* webpackChunkName: "AccountBalanceOperation" */ "../views/financialManage/AccountBalanceOperation"
            ),
        meta: {
          checkPermission: true
        }
      },
      // 账户余额 - 原厂
      {
        path: "/account-balance-factory",
        name: "AccountBalanceFactory",
        component: () =>
          import(
            /* webpackChunkName: "AccountBalanceFactory" */ "../views/financialManage/AccountBalanceFactory"
            )
      },
      // 充值
      {
        path: "/recharge",
        name: "Recharge",
        component: () =>
          import(
            /* webpackChunkName: "Recharge" */ "../views/financialManage/Recharge"
            )
      },
      // 充值第二步骤
      {
        path: "/recharge-second-step/:price",
        name: "RechargeSecondStep",
        component: () =>
          import(
            /* webpackChunkName: "RechargeSecondStep" */ "../views/financialManage/RechargeSecondStep"
            )
      },
      // 用银行卡充值
      {
        path: "/bankPaid",
        name: "bankPaid",
        component: () =>
          import(
            /* webpackChunkName: "RechargeSecondStep" */ "../views/financialManage/bankPaid.vue"
            )
      },
      // 查看明细
      {
        path: "/amount-detail",
        name: "AmountDetail",
        component: () =>
          import(
            /* webpackChunkName: "AmountDetail" */ "../views/financialManage/AmountDetail"
            )
      },
      // 绑定银行卡
      {
        path: "/bind-bank-card",
        name: "BindBankCard",
        component: () =>
          import(
            /* webpackChunkName: "BindBankCard" */ "../views/financialManage/BindBankCard"
            )
      },
      // 绑定银行卡成功
      {
        path: "/bind-bank-card-success/:bankName/:bankCode",
        name: "BindBankCardSuccess",
        component: () =>
          import(
            /* webpackChunkName: "BindBankCardSuccess" */ "../views/financialManage/BindBankCardSuccess"
            )
      },
      // 待发货订单列表
      {
        path: "/to-deliver-order-list",
        name: "ToDeliverOrderList",
        meta: {
          keepAlive: true,
          checkPermission: true
        },
        component: () =>
          import(
            /* webpackChunkName: "ToDeliverOrderList" */ "../views/orderManage/purchaseOrderFactory/ToDeliverOrderList"
            )
      },
      // 发货订单明细
      {
        path: "/deliver-order-detail",
        name: "DeliverOrderDetail",
        component: () =>
          import(
            /* webpackChunkName: "DeliverOrderDetail" */ "../views/orderManage/purchaseOrderFactory/DeliverOrderDetail"
            )
      },
      // 待发货订单详情
      {
        path: "/to-deliver-order-detail/:orderSn",
        name: "ToDeliverOrderDetail",
        meta: {
          fatherName: ["ToDeliverOrderList"]
        },
        component: () =>
          import(
            /* webpackChunkName: "ToDeliverOrderDetail" */ "../views/orderManage/purchaseOrderFactory/ToDeliverOrderDetail"
            )
      },
      // 运营总部 - 代下订单列表
      {
        path: "/operation-agent-order-list",
        name: "OperationAgentOrderList",
        meta: {
          keepAlive: true
        },
        component: () =>
          import(
            /* webpackChunkName: "OperationAgentOrderList" */ "../views/orderManage/agentOrderOperation/OperationAgentOrderList"
            )
      },
      // 运营总部 - 新增代下订单
      {
        path: "/operation-agent-order-add",
        name: "OperationAgentOrderAdd",
        meta: {
          keepAlive: true
        },
        component: () =>
          import(
            /* webpackChunkName: "OperationAgentOrderAdd" */ "../views/orderManage/agentOrderOperation/OperationAgentOrderAdd"
            )
      },
      // 运营总部 - 订单列表
      {
        path: "/operation-purchase-order-list",
        name: "OperationPurchaseOrderList",
        meta: {
          keepAlive: true,
          checkPermission: true
        },
        component: () =>
          import(
            /* webpackChunkName: "OperationPurchaseOrderList" */ "../views/orderManage/purchaseOrderOperation/OperationPurchaseOrderList"
            )
      },
      // 远期子订单详情
      {
        path: "/forward-purchase-sub-order/:orderSn/:status",
        name: "ForwardPurchaseSubOrder",
        meta: {
          fatherName: ["PurchaseOrderListFactory", "OperationPurchaseOrderList"]
        },
        component: () =>
          import(
            /* webpackChunkName: "ForwardPurchaseSubOrder" */ "../views/orderManage/purchaseOrderFactory/ForwardPurchaseSubOrder"
            )

      },
      // 编辑发货信息
      {
        path: "/edit-deliver-info/:orderSn/:type",
        name: "EditDeliverInfo",
        meta: {
          fatherName: ["PublicDeliver"]
        },
        component: () =>
          import(
            /* webpackChunkName: "EditDeliverInfo" */ "../views/order/EditDeliverInfo"
            )
      },
      // 原厂 - 退货订单列表
      {
        path: "/factory-returned-order-list",
        name: "FactoryReturnedOrderList",
        meta: {
          keepAlive: true,
          checkPermission: true
        },
        component: () =>
          import(
            /* webpackChunkName: "FactoryReturnedOrderList" */ "../views/orderManage/returnedOrderFactory/FactoryReturnedOrderList"
            )

      },
      // 原厂 - 退货订单审核列表
      {
        path: "/factory-returned-examine-order",
        name: "FactoryReturnedExamineOrder",
        meta: {
          keepAlive: true,
          checkPermission: true
        },
        component: () =>
          import(
            /* webpackChunkName: "FactoryReturnedExamineOrder" */ "../views/orderManage/returnedOrderFactory/FactoryReturnedExamineOrder"
            )

      },
      // 原厂 - 退货订单详情
      {
        path: "/factory-returned-order-detail/:orderSn/:type",
        name: "FactoryReturnedOrderDetail",
        meta: {
          fatherName: ["FactoryReturnedExamineOrder", "FactoryReturnedOrderList"]
        },
        component: () =>
          import(
            /* webpackChunkName: "FactoryReturnedOrderDetail" */ "../views/orderManage/returnedOrderFactory/FactoryReturnedOrderDetail"
            )
      },
      // 原厂 - 退货订单详情
      {
        path: "/factory-returned-order-examine/:orderSn/:type",
        name: "FactoryReturnedExamine",
        meta: {
          fatherName: ["FactoryReturnedExamineOrder"]
        },
        component: () =>
          import(
            /* webpackChunkName: "FactoryReturnedOrderDetail" */ "../views/orderManage/returnedOrderFactory/FactoryReturnedOrderDetail"
            )
      },
      // 原厂 - 退货订单待退款列表
      {
        path: "/to-refunded-list",
        name: "ToRefundedList",
        meta: {
          keepAlive: true,
          checkPermission: true
        },
        component: () =>
          import(
            /* webpackChunkName: "ToRefundedList" */ "../views/orderManage/returnedOrderFactory/ToRefundedList"
            )
      },
      // 原厂 - 退货订单待退款详情
      {
        path: "/to-refunded-detail/:orderSn/:type",
        name: "ToRefundedDetail",
        meta: {
          fatherName: ["ToRefundedList", "FactoryReturnedOrderList"]
        },
        component: () =>
          import(
            /* webpackChunkName: "ToRefundedDetail" */ "../views/orderManage/returnedOrderFactory/ToRefundedDetail"
            )
      },
      // 原厂 - 退货订单待退款详情
      {
        path: "/to-refunded-detail/:orderSn/:type",
        name: "ToRefunded",
        meta: {
          fatherName: ["ToRefundedList", "FactoryReturnedOrderList"]
        },
        component: () =>
          import(
            /* webpackChunkName: "ToRefunded" */ "../views/orderManage/returnedOrderFactory/ToRefundedDetail"
            )
      },
      // 原厂 - 退货订单待收货列表
      {
        path: "/to-received-returned-order",
        name: "ToReceivedReturnedOrder",
        meta: {
          keepAlive: true,
          checkPermission: true
        },
        component: () =>
          import(
            /* webpackChunkName: "ToReceivedReturnedOrder" */ "../views/orderManage/returnedOrderFactory/ToReceivedReturnedOrder"
            )
      },
      // 原厂 - 退货订单待收货详情
      {
        path: "/to-received-detail/:orderSn",
        name: "ToReceivedDetail",
        meta: {
          fatherName: ["ToReceivedReturnedOrder", "FactoryReturnedOrderList"]
        },
        component: () =>
          import(
            /* webpackChunkName: "ToReceivedDetail" */ "../views/orderManage/returnedOrderFactory/ToReceivedDetail"
            )
      },
      // 运营总部 - 退货订单
      {
        path: "/operation-returned-order-list",
        name: "OperationReturnedOrderList",
        meta: {
          keepAlive: true,
          checkPermission: true
        },
        component: () =>
          import(
            /* webpackChunkName: "OperationReturnedOrderList" */ "../views/orderManage/returnedOrderOperation/OperationReturnedOrderList"
            )
      },
      // 运营总部 - 退货订单待审核列表
      {
        path: "/operation-returned-examine-order",
        name: "OperationReturnedExamineOrder",
        meta: {
          keepAlive: true,
          checkPermission: true
        },
        component: () =>
          import(
            /* webpackChunkName: "OperationReturnedExamineOrder" */ "../views/orderManage/returnedOrderOperation/OperationReturnedExamineOrder"
            )
      },
      // 退款审核
      {
        path: "/refund-review",
        name: "RefundReview",
        meta: {
          keepAlive: true,
          checkPermission: true
        },
        component: () =>
          import(
            /* webpackChunkName: "RefundReview" */ "../views/financialManage/RefundReview"
            )
      },
      // 退款审核详情
      {
        path: "/refund-review-detail/:orderSn/:type",
        name: "RefundReviewDetail",
        meta: {
          fatherName: ["RefundReview", "DoneTask"]
        },
        component: () =>
          import(
            /* webpackChunkName: "RefundReviewDetail" */ "../views/financialManage/RefundReviewDetail"
            )
      },
      // 退款订单审核
      {
        path: "/refund-review-examine/:orderSn/:type",
        name: "RefundReviewExamine",
        meta: {
          fatherName: ["RefundReview"]
        },
        component: () =>
          import(
            /* webpackChunkName: "RefundReviewExamine" */ "../views/financialManage/RefundReviewDetail"
            )
      },
      // 物流明细
      {
        path: "/deliver-detail/:orderSn",
        name: "DeliverDetail",
        component: () =>
          import(
            /* webpackChunkName: "DeliverDetail" */ "../views/orderManage/DeliverDetail"
            )
      },
      // 运营总部 - 退货订单详情
      {
        path: "/operation-returned-order-detail/:orderSn/:type/:status",
        name: "OperationReturnedOrderDetail",
        meta: {
          fatherName: ["OperationReturnedExamineOrder", "OperationReturnedOrderList", "DoneTask"]
        },
        component: () =>
          import(
            /* webpackChunkName: "OperationReturnedOrderDetail" */ "../views/orderManage/returnedOrderOperation/OperationReturnedOrderDetail"
            )
      },
      // 运营总部 - 退货订单审核
      {
        path: "/operation-returned-order-examine/:orderSn/:type/:status",
        name: "OperationReturnedExamine",
        meta: {
          fatherName: ["OperationReturnedExamineOrder", "ToDoTask"]
        },
        component: () =>
          import(
            /* webpackChunkName: "OperationReturnedExamine" */ "../views/orderManage/returnedOrderOperation/OperationReturnedOrderDetail"
            )
      },
      // 原厂 - 换货订单待审核列表
      {
        path: "/change-order-examine-list-factory",
        name: "ChangeOrderExamineListFactory",
        meta: {
          keepAlive: true,
          checkPermission: true
        },
        component: () =>
          import(
            /* webpackChunkName: "ChangeOrderExamineListFactory" */ "../views/orderManage/changeOrderFactory/ChangeOrderExamineListFactory"
            )
      },
      // 原厂 - 换货订单详情
      {
        path: "/change-order-detail-factory/:orderSn/:type",
        name: "ChangeOrderDetailFactory",
        meta: {
          fatherName: ["ChangeOrderExamineListFactory", "ChangeOrderListFactory"]
        },
        component: () =>
          import(
            /* webpackChunkName: "ChangeOrderDetailFactory" */ "../views/orderManage/changeOrderFactory/ChangeOrderDetailFactory"
            )
      },
      // 原厂 - 换货订单审核
      {
        path: "/change-order-examine-factory/:orderSn/:type",
        name: "ChangeOrderExamineFactory",
        meta: {
          fatherName: ["ChangeOrderExamineListFactory"]
        },
        component: () =>
          import(
            /* webpackChunkName: "ChangeOrderExamineFactory" */ "../views/orderManage/changeOrderFactory/ChangeOrderDetailFactory"
            )
      },
      //授信列表
      {
        path: "/credit-list",
        name: "creditList",
        meta: {
          keepAlive: true,
          checkPermission: true
        },
        component: () =>
          import(
            /* webpackChunkName: "examine" */ "../views/channelCredit/creditList"
            )
      },
      //授信新增
      {
        path: "/credit-add",
        name: "creditAdd",
        meta: {
          fatherName: ['creditList']
        },
        component: () =>
          import(
            /* webpackChunkName: "examine" */ "../views/channelCredit/creditAdd"
            )
      },
      //授信编辑
      {
        path: "/credit-edit/:item",
        name: "creditEdit",
        meta: {
          fatherName: ['creditList']
        },
        component: () =>
          import(
            /* webpackChunkName: "examine" */ "../views/channelCredit/creditEdit"
            )
      },
      //租车订单分期配置
      {
        path: "/installment-list",
        name: "installmentList",
        component: () =>
          import(
            /* webpackChunkName: "examine" */ "../views/installmentConfig/installmentList"
            )
      },
      //租车订单分期配置新增或编辑
      {
        path: "/installment-addOrEdit",
        name: "installment",
        component: () =>
          import(
            /* webpackChunkName: "examine" */ "../views/installmentConfig/installmentAddOrEdit"
            )
      },
      //车型管理
      {
        path: "/vehicleModel",
        name: "vehicleModel",
        component: () =>
          import(
            /* webpackChunkName: "examine" */ "../views/installmentConfig/vehicleModel"
            ),
        meta: {
          checkPermission: true
        }
      },
      //租赁分润参数配置
      {
        path: "/profitConfiguration",
        name: "profitConfiguration",
        component: () =>
          import(
            /* webpackChunkName: "examine" */ "../views/installmentConfig/profitConfiguration"
            ),
        meta: {
          checkPermission: true
        }
      },
      //平台费
      {
        path: "/platformFee",
        name: "platformFee",
        component: () =>
          import(
            /* webpackChunkName: "examine" */ "../views/installmentConfig/platformFee"
            ),
        meta: {
          checkPermission: true
        }
      },
      // 原厂 - 换货订单列表
      {
        path: "/change-order-list-factory",
        name: "ChangeOrderListFactory",
        meta: {
          keepAlive: true,
          checkPermission: true
        },
        component: () =>
          import(
            /* webpackChunkName: "ChangeOrderListFactory" */ "../views/orderManage/changeOrderFactory/ChangeOrderListFactory"
            )
      },
      // 运营总部 - 换货订单列表
      {
        path: "/change-order-list-operation",
        name: "ChangeOrderListOperation",
        meta: {
          keepAlive: true,
          checkPermission: true
        },
        component: () =>
          import(
            /* webpackChunkName: "ChangeOrderListOperation" */ "../views/orderManage/changeOrderOperation/ChangeOrderListOperation"
            )
      },
      // 原厂 - 换货订单待收货
      {
        path: "/to-receive-change-order-list",
        name: "ToReceiveChangeOrderList",
        meta: {
          keepAlive: true,
          checkPermission: true
        },
        component: () =>
          import(
            /* webpackChunkName: "ToReceiveChangeOrderList" */ "../views/orderManage/changeOrderFactory/ToReceiveChangeOrderList"
            )
      },
      // 原厂 - 换货订单待发货
      {
        path: "/to-deliver-change-order-list",
        name: "ToDeliverChangeOrderList",
        meta: {
          keepAlive: true,
          checkPermission: true
        },
        component: () =>
          import(
            /* webpackChunkName: "ToDeliverChangeOrderList" */ "../views/orderManage/changeOrderFactory/ToDeliverChangeOrderList"
            )
      },
      // 换货订单 - 待收货详情
      {
        path: "/to-receiver-order-detail/:orderSn/:type",
        name: "ToReceiverOrderDetail",
        meta: {
          fatherName: ["ToReceiveChangeOrderList", "ToDeliverChangeOrderList", "ChangeOrderListFactory"]
        },
        component: () =>
          import(
            /* webpackChunkName: "ToReceiverOrderDetail" */ "../views/orderManage/changeOrderFactory/ToReceiverOrderDetail"
            )
      },
      // 运营总部 - 换货订单详情
      {
        path: "/change-order-detail-operation/:orderSn",
        name: "ChangeOrderDetailOperation",
        meta: {
          fatherName: ["ChangeOrderListOperation"]
        },
        component: () =>
          import(
            /* webpackChunkName: "ChangeOrderDetailOperation" */ "../views/orderManage/changeOrderOperation/ChangeOrderDetailOperation"
            )
      },
      // 换货订单- 收发货详情
      {
        path: "/deliver-receive-detail/:orderSn/:type/:producerCorporationName/:purchaserCorporationName",
        name: "DeliverReceiveDetail",
        component: () =>
          import(
            /* webpackChunkName: "DeliverReceiveDetail" */ "../views/orderManage/changeOrderOperation/DeliverReceiveDetail"
            )
      },
      // 充值结果页面
      {
        path: "/recharge-result/:orderId",
        name: "RechargeResult",
        component: () =>
          import(
            /* webpackChunkName: "RechargeResult" */ "../views/financialManage/RechargeResult"
            )
      },
      // 账号管理
      {
        path: "/userList",
        name: "userList",
        meta: {
          keepAlive: true,
          checkPermission: true
        },
        component: () =>
          import(
            /* webpackChunkName: "RechargeResult" */ "../views/manageAccounts/user-list"
            )
      },
      // 新增或编辑用户
      {
        path: "/addOrEdit-userList",
        name: "addOrEditUserList",
        meta: {
          fatherName: ['userList']
        },
        component: () =>
          import(
            /* webpackChunkName: "RechargeResult" */ "../views/manageAccounts/addOredit-user-list"
            )
      },
      // 角色管理
      {
        path: "/roleList",
        name: "roleList",
        meta: {
          keepAlive: true,
          checkPermission: true
        },
        component: () =>
          import(
            /* webpackChunkName: "RechargeResult" */ "../views/manageAccounts/role-list"
            )
      },
      // 权限设置
      {
        path: "/set-privilege",
        name: "setPrivilege",
        meta: {
          fatherName: ['roleList']
        },
        component: () => import("../views/manageAccounts/set-privilege.vue")
      },
      // 待办中心 - 待办任务
      {
        path: "/to-do-task",
        name: "ToDoTask",
        meta: {
          keepAlive: true,
          checkPermission: true
        },
        component: () =>
          import(
            /* webpackChunkName: "ToDoTask" */ "../views/toDoCenter/ToDoTask"
            )
      },
      // 待办中心 - 已办任务
      {
        path: "/done-task",
        name: "DoneTask",
        meta: {
          keepAlive: true,
          checkPermission: true
        },
        component: () =>
          import(
            /* webpackChunkName: "DoneTask" */ "../views/toDoCenter/DoneTask"
            )
      },
      // 租赁管理 - 系统设置
      {
        path: "/system-set",
        name: "SystemSet",
        component: () =>
          import(
            /* webpackChunkName: "SystemSet" */ "../views/leaseManagement/SystemSet"
            )
      },
      // 手续费账户明细
      {
        path: "/commission-account-detail",
        name: "CommissionAccountDetail",
        component: () =>
          import(
            /* webpackChunkName: "CommissionAccountDetail" */ "../views/financialManage/CommissionAccountDetail"
            )
      },
      // 站点管理
      {
        path: "/service-site",
        name: "ServiceSite",
        component: () =>
          import(
            /* webpackChunkName: "CommissionAccountDetail" */ "../views/leaseManagement/ServiceSite"
            ),
        meta: {
          checkPermission: true
        }
      },
      // 站点管理详情
      {
        path: "/service-site-detail",
        name: "ServiceSiteDetail",
        component: () =>
          import(
            /* webpackChunkName: "CommissionAccountDetail" */ "../views/leaseManagement/ServiceSiteDetail"
            )
      },
      // 站点管理编辑
      {
        path: "/service-site-edit",
        name: "ServiceSiteEdit",
        component: () =>
          import(
            /* webpackChunkName: "CommissionAccountDetail" */ "../views/leaseManagement/ServiceSiteEdit"
            )
      },
      // 地图展示
      {
        path: "/current-location",
        name: "CurrentLocation",
        component: () =>
          import(
            /* webpackChunkName: "CommissionAccountDetail" */ "../views/leaseManagement/CurrentLocation"
            )
      },
      //  租赁监控  车辆管理
      {
        path: "/car-manage",
        name: "CarManage",
        component: () =>
          import(
            /* webpackChunkName: "CommissionAccountDetail" */ "../views/LeaseManage/FinanceManage/CarManage.vue"
            ),
        meta: {
          checkPermission: true
        }
      },
      //  租赁监控  电池管理
      {
        path: "/battery-manage",
        name: "BatteryManage",
        component: () =>
          import(
            /* webpackChunkName: "CommissionAccountDetail" */ "../views/LeaseManage/FinanceManage/BatteryManage.vue"
            ),
        meta: {
          checkPermission: true
        }
      },
      // 租赁业务的资产管理 车辆管理详情页
      {
        path: "/finance-manage-detail",
        name: "CarDetail",
        component: () =>
          import(
            /* webpackChunkName: "CommissionAccountDetail" */ "../views/LeaseManage/FinanceManage/Detail.vue"
            )
      },
      // 租赁业务的资产管理 电池管理详情页
      {
        path: "/battery-detail",
        name: "BatteryDetail",
        component: () =>
          import(
            /* webpackChunkName: "CommissionAccountDetail" */ "../views/LeaseManage/FinanceManage/BatteryDetail.vue"
            )
      },
      //   合作站长管理
      {
        path: "/web-master-manage",
        name: "WebMasterManage",
        component: () =>
          import(
            /* webpackChunkName: "CommissionAccountDetail" */ "../views/LeaseManage/WebMasterManage/index.vue"
            )
      },
      //   查看站长详情
      {
        path: "/web-master-detail",
        name: "WebMasterDetail",
        component: () =>
          import(
            /* webpackChunkName: "CommissionAccountDetail" */ "../views/LeaseManage/WebMasterManage/MasterDetail.vue"
            )
      },
      // 修改站长信息
      {
        path: "/web-master-edit",
        name: "EditMaster",
        component: () =>
          import(
            /* webpackChunkName: "CommissionAccountDetail" */ "../views/LeaseManage/WebMasterManage/EditMaster.vue"
            )
      },
      // (运营总部参数配置)系统设置
      {
        path: "/system-setting",
        name: "SystemSetting",
        component: () =>
          import(
            /* webpackChunkName: "CommissionAccountDetail" */ "@/views/LeaseManage/SystemSetting/index.vue"
            ),
        meta: {
          checkPermission: true
        }
      },
      //分账计划
      {
        path: "/account-division",
        name: "AccountDivision",
        component: () =>
          import(
            /* webpackChunkName: "CommissionAccountDetail" */ "@/views/LeaseManage/AccountDivision/index.vue"
            )
      },
      //分账计划详情页
      {
        path: "/account-division-detail",
        name: "AccountDivisionDetail",
        component: () =>
          import(
            /* webpackChunkName: "CommissionAccountDetail" */ "@/views/LeaseManage/AccountDivision/Detail.vue"
            )
      },
      // 运维工单
      {
        path: "/operation-list",
        name: "OperationList",
        component: () =>
          import(
            /* webpackChunkName: "CommissionAccountDetail" */ "@/views/LeaseManage/Operation/index.vue"
            ),
        meta: {
          checkPermission: true
        }
      },
      // 运维工单详情页
      {
        path: "/operation-detail",
        name: "OperationDetail",
        component: () =>
          import(
            /* webpackChunkName: "CommissionAccountDetail" */ "@/views/LeaseManage/Operation/Detail.vue"
            )
      },
      // 运营工单
      {
        path: "/work-order-list",
        name: "WorkOrderList",
        component: () =>
          import(
            /* webpackChunkName: "CommissionAccountDetail" */ "@/views/LeaseManage/WorkOrder/index.vue"
            ),
        meta: {
          checkPermission: true
        }
      },
      // 运营工单详情页
      {
        path: "/work-order-detail",
        name: "WorkOrderDetail",
        component: () =>
          import(
            /* webpackChunkName: "CommissionAccountDetail" */ "@/views/LeaseManage/WorkOrder/Detail.vue"
            )
      },
      // 运营警报工单
      {
        path: "/operation-alarm-list",
        name: "OperationAlarm",
        component: () =>
          import(
            /* webpackChunkName: "CommissionAccountDetail" */ "@/views/LeaseManage/OperationAlarm/index.vue"
            ),
        meta: {
          checkPermission: true
        }
      },
      // 运营警报详情页
      {
        path: "/operation-alarm-detail",
        name: "OperationAlarmDetail",
        component: () =>
          import(
            /* webpackChunkName: "CommissionAccountDetail" */ "@/views/LeaseManage/OperationAlarm/Detail.vue"
            )
      },
      // 收款银行卡管理
      {
        path: "/bank-card",
        name: "BankCard",
        component: () =>
          import(
            /* webpackChunkName: "RechargeResult" */ "../views/manageAccounts/BankCard"
            ),
        meta: {
          checkPermission: true
        }
      },
      // 原厂收款银行卡管理
      {
        path: "/factory-bank-card",
        name: "FactoryBankCard",
        component: () =>
          import(
            /* webpackChunkName: "RechargeResult" */ "../views/manageAccounts/FactoryBankCard"
            ),
        meta: {
          checkPermission: true
        }
      },
      // 到账审核
      {
        path: "/account-list",
        name: "AccountList",
        meta: {
          keepAlive: true,
          checkPermission: true
        },
        component: () =>
          import(
            /* webpackChunkName: "RechargeResult" */ "../views/account/AccountList"
            )
      },
      // 到账审核详情
      {
        path: "/account-detail",
        name: "AccountDetail",
        meta: {
          fatherName: ['AccountList']
        },
        component: () =>
          import(
            /* webpackChunkName: "RechargeResult" */ "../views/account/AccountDetail"
            )
      },
      // 出纳支付
      {
        path: "/cashier-list",
        name: "CashierList",
        meta: {
          keepAlive: true,
          checkPermission: true
        },
        component: () =>
          import(
            /* webpackChunkName: "RechargeResult" */ "../views/account/CashierList"
            )
      },
      // 出纳支付详情
      {
        path: "/cashier-detail",
        name: "CashierDetail",
        meta: {
          fatherName: ['CashierList']
        },
        component: () =>
          import(
            /* webpackChunkName: "RechargeResult" */ "../views/account/CashierDetail"
            )
      },
      // 农行账单
      {
        path: "/agricultural-bank-list",
        name: "AgriculturalBankList",
        meta: {
          keepAlive: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "RechargeResult" */ "../views/account/AgriculturalBankList"
            )
      },
      // 农行账单详情
      {
        path: "/agricultural-detail",
        name: "AgriculturalDetail",
        meta: {
          fatherName: ['AgriculturalBankList']
        },
        component: () =>
          import(
            /* webpackChunkName: "RechargeResult" */ "../views/account/AgriculturalDetail"
            )
      },
      // 收付款记录
      {
        path: "/receipt-pay-record",
        name: "ReceiptAndPayRecords",
        component: () =>
          import(
            /* webpackChunkName: "RechargeResult" */ "../views/financialManage/ReceiptAndPayRecords.vue"
            )
      },
      // 租赁订单 - 电行世界
      {
        path: "/trial-lease-order-dxsj",
        name: "TrialLeaseOrderDxsj",
        component: () =>
          import(
            /* webpackChunkName: "TrialLeaseOrderDxsj" */ "../views/cTerminalData/TrialLeaseOrderDxsj"
            ),
        meta: {
          checkPermission: true
        }
      },
      // 租赁订单详情 - 电行世界
      {
        path: "/trial-lease-detail-dxsj/:id",
        name: "TrialLeaseDetailDxsj",
        component: () =>
          import(
            /* webpackChunkName: "TrialLeaseDetailDxsj" */ "../views/cTerminalData/TrialLeaseDetailDxsj"
            )
      },
      // 电池规格
      {
        path: "/battery-specification",
        name: "BatterySpecification",
        component: () =>
          import(
            /* webpackChunkName: "BatterySpecification" */ "../views/battery/BatterySpecification"
            ),
        meta: {
          checkPermission: true
        }
      },
      // 运营区域设置
      {
        path: "/operation-area-list",
        name: "OperationAreaList",
        meta: {
          keepAlive: true,
          checkPermission: true
        },
        component: () =>
          import(
            /* webpackChunkName: "OperationAreaList" */ "../views/operationManagement/OperationAreaList"
            )
      },
      // 设置电子围栏
      {
        path: "/set-fence/:areaId",
        name: "SetFence",
        meta: {
          fatherName: ["OperationAreaList"]
        },
        component: () =>
          import(
            /* webpackChunkName: "SetFence" */ "../views/operationManagement/SetFence"
            )
      },
      // 分账账单
      {
        path: "/division-bill",
        name: "DivisionBill",
        meta: {
          keepAlive: true
        },
        component: () =>
          import(
            /* webpackChunkName: "DivisionBill" */ "../views/financialManage/DivisionBill"
            )
      },
      // 分账账单明细
      {
        path: "/division-bill-detail/:planId",
        name: "DivisionBillDetail",
        meta: {
          fatherName: ["DivisionBill"]
        },
        component: () =>
          import(
            /* webpackChunkName: "DivisionBillDetail" */ "../views/financialManage/DivisionBillDetail"
            )
      },
      // 编辑收货人地址
      {
        path: "/edit-receiver-address/:type",
        name: "EditReceiverAddress",
        meta: {
          fatherName: ["OperationAgentOrderAdd"]
        },
        component: () =>
          import(
            /* webpackChunkName: "EditReceiverAddress" */ "../views/orderManage/agentOrderOperation/EditReceiverAddress"
            )
      }
    ]
  },
  //登陆主页
  {
    path: "",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "camp" */ "../views/web/Login.vue")
  }
];

const router = new VueRouter({
  routes
});

router.beforeEach((to, from, next) => {
  NProgress.start();
  // 检查是否有当前页面的菜单权限
  const menuList = store.state.menuList;
  if (to.meta.checkPermission && !menuList.includes(to.name)) {
    NProgress.done();
    next({ name: "Login" });
  }

  const loggedIn = localStorage.getItem("userId");
  if (to.matched.some(record => record.meta.requiresAuth) && !loggedIn) {
    NProgress.done();
    next({ name: "Login" });
  }
  window.onkeydown = function() {
    store.state.isModify = true;
  };
  const isModify = store.state.isModify;
  if (to.name !== from.name && isShowLeaveDialog(from) && isModify) {
    NProgress.done();
    Modal.confirm({
      title: "提示",
      content: "确认是否离开当前页面",
      okText: "确认",
      cancelText: "取消",
      onOk() {
        next();
      }
    });
  } else {
    NProgress.done();
    next();
  }
});

router.afterEach(() => {
  NProgress.done();
});
export default router;

export function isShowLeaveDialog(from) {
  const name = from.name;
  let isShow = false;
  const routerList = router.options.routes;
  if (routerList.length > 0) {
    routerList.forEach(item => {
      if (name === item.name) {
        isShow = !!(item.meta && item.meta.isShowLeave);
      } else {
        if (item.children && item.children.length > 0) {
          for (let i = 0; i < item.children.length; i = i + 1) {
            if (name === item.children[i].name) {
              if (item.children[i].meta && item.children[i].meta.isShowLeave) {
                isShow = true;
              }
              break;
            } else {
              isShow = false;
            }
          }
        }
      }
    });
  }
  return isShow;
}
