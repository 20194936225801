<template>
  <a-range-picker
    :allow-clear="true"
    :value="dateValue"
    @change="onChange"
    style="width: 100%"
  />
</template>

<script>
import Moment from "moment";

export default {
  props: {
    field: {
      type: Object,
      default: () => ({})
    },
    form: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    dateValue() {
      return this.field.key
        .split(",")
        .map(key => (this.form[key] ? Moment(this.form[key]) : ""));
    }
  },
  methods: {
    onChange(dates, dateStrings) {
      this.$emit(
        "change",
        dateStrings.map(date =>
          date
            ? Moment(date).format(this.field.format || "YYYY-MM-DD HH:mm:ss")
            : ""
        )
      );
    }
  }
};
</script>
