<template>
  <a-select :value="form[field.key]" @change="onChange">
    <a-select-option
      v-for="option in options || []"
      :value="option.value"
      :key="option.value"
      allowClear
    >
      {{ option.label }}
    </a-select-option>
  </a-select>
</template>

<script>
export default {
  props: {
    field: {
      type: Object,
      default: () => ({})
    },
    form: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      options: []
    };
  },
  created() {
    if (this.field.getOptions) {
      this.field.getOptions().then(resp => {
        this.options = resp;
      });
    } else {
      this.options = this.field.options;
    }
  },
  methods: {
    onChange(value) {
      this.$emit("change", value);
    }
  }
};
</script>
