<template>
  <a-radio-group :value="form[field.key]" @change="onChange">
    <a-radio
      :value="option.value"
      v-for="option in field.options || []"
      :key="option.value"
    >
      {{ option.label }}
    </a-radio>
  </a-radio-group>
</template>

<script>
export default {
  name: "JRadioGroup",
  props: {
    field: {
      type: Object,
      default: () => ({})
    },
    form: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    onChange(value) {
      this.$emit("change", value.target.value);
    }
  }
};
</script>

<style scoped></style>
