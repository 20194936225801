<template>
  <a-input v-model="form[field.key]" />
</template>

<script>
export default {
  props: {
    field: {
      type: Object,
      default: () => ({})
    },
    form: {
      type: Object,
      default: () => ({})
    }
  }
};
</script>
