<template>
  <a-cascader
    :value="dateValue"
    :options="areaData"
    :field-names="field.fieldNames"
    placeholder="请选择"
    @change="onChange"
    :showSearch="showSearch"
  ></a-cascader>
</template>

<script>
export default {
  name: "JCascader",
  props: {
    field: {
      type: Object,
      default: () => ({})
    },
    form: {
      type: Object,
      default: () => ({})
    }
  },

  computed: {
    dateValue() {
      return this.field.key
        .split(",")
        .map(key => (this.form[key] ? this.form[key] : ""));
    }
  },
  created() {
    if (this.field.getAreaData) {
      this.field.getAreaData().then(resp => {
        this.areaData = resp;
      });
    } else {
      this.areaData = this.field.areaData;
    }
  },
  data() {
    return {
      showSearch: {
        filter: (inputValue, path) => {
          return path.some(option => {
            return this.fullText(inputValue, option.productName);
          });
        },
        matchInputWidth: true
      },
      areaData: []
    };
  },
  methods: {
    fullText(keyword, text) {
      let hlen = text.length;
      let nlen = keyword.length;
      if (nlen > hlen) {
        return false;
      }
      if (nlen === hlen) {
        return keyword === text;
      }
      outer: for (let i = 0, j = 0; i < nlen; i++) {
        let nch = keyword.charCodeAt(i);
        while (j < hlen) {
          if (text.charCodeAt(j++) === nch) {
            continue outer;
          }
        }
        return false;
      }
      return true;
    },
    onChange(dates, dateStrings) {
      if (!dateStrings) {
        dateStrings = ["", "", ""];
      }
      this.$emit(
        "change",
        dateStrings.map(date => (date.productId ? date.productId : ""))
      );
    }
  }
};
</script>

<style scoped></style>
