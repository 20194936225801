<template>
  <a-select
    show-search
    :filter-option="filterOption"
    :value="form[field.key]"
    @change="onChange"
    allowClear
  >
    <a-select-option
      v-for="option in options || []"
      :value="option.value"
      :key="option.value"
    >
      {{ option.label }}
    </a-select-option>
  </a-select>
</template>

<script>
export default {
  props: {
    field: {
      type: Object,
      default: () => ({})
    },
    form: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      options: []
    };
  },
  created() {
    if (this.field.getOptions) {
      this.field.getOptions().then(resp => {
        this.options = resp;
      });
    } else {
      this.options = this.field.options;
    }
  },
  methods: {
    onChange(value) {
      this.$emit("change", value);
    },
    onSearch(value) {
      if (value.trim()) {
        this.field.getOptions(value).then(resp => {
          this.options = resp;
        });
      }
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    }
  }
};
</script>
