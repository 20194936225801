import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store/store";
import infiniteScroll from "vue-infinite-scroll";
import {
  Alert,
  Avatar,
  Badge,
  Breadcrumb,
  Button,
  Card,
  Carousel,
  Cascader,
  Checkbox,
  Col,
  Collapse,
  ConfigProvider,
  DatePicker,
  Descriptions,
  Divider,
  Dropdown,
  Empty,
  Form,
  FormModel,
  Icon,
  Input,
  InputNumber,
  Layout,
  List,
  Menu,
  message,
  Modal,
  notification,
  PageHeader,
  Pagination,
  Popconfirm,
  Popover,
  Radio,
  Row,
  Select,
  Space,
  Spin,
  Statistic,
  Steps,
  Switch,
  Table,
  Tabs,
  Tag,
  TimePicker,
  Tooltip,
  Transfer,
  Tree,
  TreeSelect,
  Upload
} from "ant-design-vue";

import VueAMap from "vue-amap";
import CKEditor from "ckeditor4-vue";
import SvgIcon from "vue-svg-icon/Icon";
import "ant-design-vue/dist/antd.less";
import "nprogress/nprogress.css";
import moment from "moment";
import Viewer from "v-viewer";
import "viewerjs/dist/viewer.css";

import CommonSearch from "./components/CommonSearch";

const MyIcon = Icon.createFromIconfontCN({
  scriptUrl: "//at.alicdn.com/t/font_3325424_je80higmhu.js" // 在 iconfont.cn 上生成
});
Vue.component("my-icon", MyIcon);
Vue.config.productionTip = true;
Vue.config.errorHandler = function(err) {
  console.log("global", err);
  message.error("出错了");
};
Vue.prototype.$message = message;
Vue.prototype.$notification = notification;
Vue.prototype.$confirm = Modal.confirm;
Vue.prototype.$warning = Modal.warning;
Vue.prototype.$success = Modal.success;

Vue.component("icon", SvgIcon);

Vue.use(TimePicker);
Vue.use(Layout);
Vue.use(Tabs);
Vue.use(Form);
Vue.use(Input);
Vue.use(Button);
Vue.use(Icon);
Vue.use(Checkbox);
Vue.use(Alert);
Vue.use(Menu);
Vue.use(Dropdown);
Vue.use(Avatar);
Vue.use(Breadcrumb);
Vue.use(Table);
Vue.use(Tag);
Vue.use(Divider);
Vue.use(PageHeader);
Vue.use(Modal);
Vue.use(Row);
Vue.use(Col);
Vue.use(Space);
Vue.use(Upload);
Vue.use(Select);
Vue.use(Radio);
Vue.use(Tree);
Vue.use(TreeSelect);
Vue.use(Card);
Vue.use(Empty);
Vue.use(Spin);
Vue.use(Switch);
Vue.use(DatePicker);
Vue.use(Descriptions);
Vue.use(Badge);
Vue.use(Statistic);
Vue.use(CKEditor);
Vue.use(List);
Vue.use(Collapse);
Vue.use(FormModel);
Vue.use(Carousel);
Vue.use(Popconfirm);
Vue.use(Tooltip);
Vue.use(InputNumber);
Vue.use(ConfigProvider);
Vue.use(infiniteScroll);
Vue.use(Pagination);
Vue.use(Cascader);
Vue.use(Steps);
Vue.use(Popover);
Vue.use(Transfer);

Vue.component("common-search", CommonSearch);

Vue.use(VueAMap);

VueAMap.initAMapApiLoader({
  key: "8ff794d16e2643c4d0ef597224524cb3",
  plugin: [
    "AMap.Autocomplete",
    "AMap.PlaceSearch",
    "AMap.Scale",
    "AMap.OverView",
    "AMap.ToolBar",
    "AMap.MapType",
    "AMap.PolyEditor",
    "AMap.CircleEditor"
  ],
  // 默认高德 sdk 版本为 1.4.4
  v: "1.4.4"
});
// 图片预览插件引入

Vue.use(Viewer);
Viewer.setDefaults({
  inline: false, // 是否直接展示
  button: true, // 右上角按钮
  navbar: true, // 底部缩略图
  title: true, // 当前图片标题
  toolbar: true, // 底部工具栏
  tooltip: true, // 显示缩放百分比
  movable: true, // 是否可以移动
  zoomable: true, // 是否可以缩放
  rotatable: true, // 是否可旋转
  scalable: true, // 是否可翻转
  transition: true, // 使用 CSS3 过度
  fullscreen: true, // 播放时是否全屏
  keyboard: true, // 是否支持键盘
  url: "data-source"
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
Vue.prototype.$moment = moment;
moment.locale("zh-cn");
