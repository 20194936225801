<template>
  <a-form-model :model="form" layout="inline" :label-col="labelCol">
    <a-form-model-item
      v-for="field in fields"
      :label="field.label"
      :key="field.name"
      :class="['common-search_form-item', field.component === 'j-range-time-picker' && 'common-search_form-item-time']"
    >
      <component
        v-if="field.component"
        :is="field.component"
        :field="field"
        :form="form"
        :changeOnSelect="field.changeOnSelect"
        @change="val => onChange(field, val)"
      />
    </a-form-model-item>
    <slot></slot>
    <a-form-model-item class="common-search_button">
      <a-button type="primary" icon="search" @click="onSearch">
        搜索
      </a-button>
      <a-button
        type="white"
        icon="undo"
        class="common-search_button_reset"
        @click="onReset"
      >
        重置
      </a-button>
    </a-form-model-item>
  </a-form-model>
</template>

<script>
import components from "./components";

export default {
  components,
  props: {
    fields: {
      type: Array,
      default: () => []
    },
    labelCol: {
      type: Object,
      default: () => ({ span: 4 })
    }
  },
  data() {
    return {
      form: {}
    };
  },
  created() {
    this.initForm();
  },
  methods: {
    // 状态初始化
    initForm() {
      this.form = this.fields.reduce((t, n) => {
        const keys = n.key.split(",");
        const defaultValues = (n.defaultValue || "").split(",");
        return {
          ...t,
          ...keys.reduce(
            (obj, key, i) => ({ ...obj, [key]: defaultValues[i] || "" }),
            {}
          )
        };
      }, {});
    },
    // 状态更新
    onChange(field, value) {
      const values = Array.isArray(value) ? value : [value];
      field.key.split(",").forEach((key, i) => (this.form[key] = values[i]));
    },
    // 搜索
    onSearch() {
      this.$emit("search", { ...this.form });
    },
    // 重置
    onReset() {
      this.$emit("reset");
      this.initForm();
      this.onSearch();
    }
  }
};
</script>

<style scoped>
.common-search_form-item {
  width: 25%;
  display: inline-flex;
  margin-bottom: 24px;
}
.common-search_button {
  margin-left: 30px;
}
.common-search_button_reset {
  margin-left: 8px;
}
/deep/.common-search_form-item .ant-form-item-control-wrapper {
  width: 70%;
}
</style>
