<template>
  <a-cascader
    :value="dateValue"
    :options="areaData"
    :field-names="field.fieldNames"
    change-on-select
    placeholder="请选择"
    @change="onChange"
  ></a-cascader>
</template>

<script>
export default {
  name: "JCascader",
  props: {
    field: {
      type: Object,
      default: () => ({})
    },
    form: {
      type: Object,
      default: () => ({})
    }
  },

  computed: {
    dateValue() {
      return this.field.key
        .split(",")
        .map(key => (this.form[key] ? this.form[key] : ""));
    }
  },
  created() {
    if (this.field.getAreaData) {
      this.field.getAreaData().then(resp => {
        this.areaData = resp;
      });
    } else {
      this.areaData = this.field.areaData;
    }
  },
  data() {
    return {
      areaData: []
    };
  },
  methods: {
    onChange(dates, dateStrings) {
      if (!dateStrings) {
        dateStrings = ["", "", ""];
      }
      this.$emit(
        "change",
        dateStrings.map(date => (date.areaCode ? date.areaCode : ""))
      );
    }
  }
};
</script>

<style scoped></style>
