import JInput from "./JInput.vue";
import JSelect from "./JSelect.vue";
import JRangePicker from "./JRangePicker.vue";
import JTimePicker from "./JTimePicker.vue";
import JCascader from "./JCascader.vue";
import JCascaderSearch from "./JCascaderSearch.vue";
import JSelectGet from "./JSelectGet.vue";
import jSelectSearch from "./JSelectSearch.vue";
import jRadioGroup from "./JRadioGroup.vue";

export default {
  JInput,
  JSelect,
  JRangePicker,
  JTimePicker,
  JCascader,
  JSelectGet,
  jSelectSearch,
  jRadioGroup,
  JCascaderSearch
};
