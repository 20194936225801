<template>
  <a-config-provider :locale="locale">
    <div id="app">
      <router-view />
    </div>
  </a-config-provider>
</template>
<script>
import zhCN from "ant-design-vue/lib/locale-provider/zh_CN";

export default {
  data() {
    return {
      locale: zhCN
    };
  },
  watch: {
    $route(to, from) {
      if (to.name !== from.name) {
        this.$store.state.isModify = false;
      }
      if (to.name) {
        const menuList = this.$store.state.userMenu;
        menuList.forEach(item => {
          if (item.path === to.name) {
            this.$store.state.selectedTopMenuId = item.menuId;
          } else {
            if (item.subMenus) {
              item.subMenus.forEach(sub => {
                if (sub.path === to.name) {
                  this.$store.state.selectedTopMenuId = item.menuId;
                  this.$store.dispatch("setCurSubMenuList", item.subMenus);
                  this.$store.state.selectedSubmenuId = sub.menuId;
                } else if (sub.subMenus) {
                  sub.subMenus.forEach(subItem => {
                    if (subItem.path === to.name) {
                      this.$store.state.selectedTopMenuId = item.menuId;
                      this.$store.dispatch("setCurSubMenuList", item.subMenus);
                      this.$store.state.selectedSubmenuId = subItem.menuId;
                    }
                  });
                }
              });
            }
          }
        });
      }
    }
  }
};
</script>
<style>
/deep/ .side-menu-container {
  background-color: #fff;
  z-index: 1000;
}

.main-container {
  padding: 0 24px 24px;
  min-height: 88vh !important;
}
.content-container {
  background-color: #fff;
  padding: 24px;
  margin: 0;
  min-height: 600px;
}

.search-box {
  width: 260px;
}
.data-table {
  margin-top: 20px;
}
#first-search-item {
  padding-left: 18px;
}
#search-form-btns {
  padding-top: 4px;
}
.search-result-list {
  margin-top: 16px;
}
.layer-icon {
  width: 32px;
  height: 32px;
  margin-left: 10px;
}
.layer-name {
  margin-left: 15px;
  margin-top: 4px;
}
.del-attachment {
  margin-left: 4px;
}
.leaflet-control-layers-selector {
  display: none;
}
.comp-type-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-right: 4px;
}
.layer-icon {
  border: 1px #ababab solid;
  padding: 2px;
}
.layer-name {
  color: #ababab;
}
.leaflet-control-layers-overlays {
  padding-top: 12px;
}
.popup-tbl td {
  border: 1px solid #5e5d5d;
  min-width: 76px;
}
.dates-link {
  margin-right: 20px;
  cursor: pointer;
}
.active-link {
  color: #1890ff;
}
.city-img {
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: -1;
}
.city-img img {
  width: 100%;
  height: 100%;
}
.tally {
  height: 60px;
  position: absolute;
  left: -7px;
  top: -7px;
  z-index: 1;
}
.tally-list {
  padding-top: 60px;
}
.title {
  padding: 30px;
  display: flex;
  width: 100%;
}
.title-left {
  text-align: left;
}
.title-center {
  text-align: center;
}
.title-right {
  text-align: right;
}
.title-empty {
  height: 30px;
}
.title-border {
  width: 10px;
  height: 30px;
  background-color: rgba(253, 93, 62, 1);
}
.title-text {
  margin-left: 16px;
}
.text-all {
  padding: 0 30px;
  overflow-y: auto;
}
.all-text-color {
  color: rgba(245, 60, 24, 100);
}
.cursor {
  cursor: pointer;
}
.image {
  width: 100%;
  height: 100%;
}

.button-item {
  margin-right: 15px;
}

.ant-space a {
  white-space: nowrap;
}

.search__container {
  box-sizing: border-box;
  padding: 20px;
  margin: 24px;
  background-color: rgba(255, 255, 255, 1);
}

.table__container {
  box-sizing: border-box;
  padding: 20px;
  margin: 0 24px 24px 24px;
  background-color: rgba(255, 255, 255, 1);
}

.card__container {
  box-sizing: border-box;
  padding: 20px;
  margin: 24px 24px 0 24px;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.24);
}

.container__title {
  color: rgba(16, 16, 16, 1);
  font-size: 14px;
  font-weight: bold;
}

.tree__select .ant-select-tree li ul .ant-select-tree-checkbox-inner {
  background-color: rgb(245, 245, 245);
  border-color: rgb(245, 245, 245);
}
.tree__select .ant-select-tree li ul .ant-select-tree-checkbox-inner::after {
  border-color: rgb(184, 184, 184);
}
.tree__select .ant-select-tree li ul {
  pointer-events: none;
}
.status--orange {
  color: #f08509;
}

.border--right {
  border-right: 1px solid #e3e3e3;
}
</style>
