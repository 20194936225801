<template>
  <a-select
    show-search
    :filter-option="false"
    :value="form[field.key]"
    @change="onChange"
    @search="onSearch"
    allowClear
  >
    <a-select-option
      v-for="option in options || []"
      :value="option.value"
      :key="option.value"
    >
      {{ option.label }}
    </a-select-option>
  </a-select>
</template>

<script>
export default {
  props: {
    field: {
      type: Object,
      default: () => ({})
    },
    form: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      options: []
    };
  },
  created() {
    this.field.getOptions("").then(resp => {
      this.options = resp;
    });
  },
  methods: {
    onChange(value) {
      if (value !== undefined && !value.trim()) {
        this.options = [];
      }
      this.$emit("change", value);
    },
    onSearch(value) {
      this.field.getOptions(value).then(resp => {
        this.options = resp;
      });
    }
  }
};
</script>
